<template>
    <div id="app">
        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef">
            <el-form-item label="" prop="id_card">
                <el-input v-model="ruleForm.id_card" placeholder="请输入身份证号" clearable></el-input>
                <el-button type="primary" @click="submitForm">下 载</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {getPdf} from "./request/api";

    export default {
        name: 'App',
        data() {
            return {
                ruleForm: {
                    id_card: '',
                },
                rules: {
                    id_card: [
                        {required: true, message: '请输入身份证号', trigger: 'blur'},
                    ],
                }
            }
        },
        created() {
        },
        methods: {
            submitForm() {
                this.$refs['ruleFormRef'].validate((valid) => {
                    if (!valid) return false;
                    getPdf(this.ruleForm)
                        .then(res => {
                            console.log(res)
                            if (res.code != 200) return this.$message.error(res.message);
                            this.$message.success(res.message);
                            window.location.href = res.url;
                            // window.open(res.url, '_blank');
                        })
                });
            },
        },
    }
</script>

<style lang="less">
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100%;
        position: relative;
    }

    .el-form {
        min-width: 320px;
        max-width: 420px;
        width: 30%;
        height: 40px;
        border-radius: 8px;
        background: #ffffff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .el-form-item {
            margin: 0;
            width: 100%;
        }

        .el-form-item__content {
            display: flex;
            flex-flow: row;

            .el-input {
                flex: 1;
                .el-input__inner {
                    width: 100%;
                    color: #000;
                    border-width: 3px;
                    border-radius: 4px 0 0 4px;
                    border-right: none;
                    border-color: #563279;
                }

                &.is-focus .el-input__inner {
                    border-color: #563279;
                }

                .el-input__inner:focus {
                    border-color: #563279 !important;
                }

                .el-input__inner:hover {
                    border-color: #563279 !important;
                }
            }

            .el-button {
                /*margin-left: 10px;*/
                color: #ffffff;
                border: 1px solid #563279;
                background-color: #563279;
                border-radius: 0 4px 4px 0;
                &:hover{
                    opacity: 0.9;
                }
            }
        }
    }
</style>
