import axios from "axios";
import qs from 'qs';
import {ElMessage} from 'element-ui'
import {SERVER_URL} from "../config/server";

const instance = axios.create({
    timeout: 3 * 60 * 1000,
})

instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.message.includes('timeout')) {
            ElMessage.warning('请求超时');
        }
        return Promise.reject(error);
    }
)

export function get(url, params) {
    return new Promise((resolve, reject) => {
        instance.get(SERVER_URL + url, {
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params, {indices: false})
            }
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export function post(url, params) {
    return new Promise((resolve, reject) => {
        instance.post(SERVER_URL + url, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}
